import Vue from 'vue';
import Router from 'vue-router';
import { getItem } from '@/helpers/persistance-storage';

// Containers
const TheContainer = () => import('@/containers/TheContainer');

// Views
const Auth = () => import('@/views/Auth');
const Register = () => import('@/views/Register/Register');
const Login = () => import('@/views/Login/Login');
const ProfileWebmaster = () => import('@/views/Profile/ProfileWebmaster');
const ProfileAdvertiser = () => import('@/views/Profile/ProfileAdvertiser');
const Sites = () => import('@/views/Sites/Sites');
const SiteCreate = () => import('@/views/Sites/SiteCreate');
const SiteEdit = () => import('@/views/Sites/SiteEdit');
const Blocks = () => import('@/views/Blocks/Blocks');
const BlockCreate = () => import('@/views/Blocks/BlockCreate');
const BlockEdit = () => import('@/views/Blocks/BlockEdit');
const StatisticsWebmaster = () => import('@/views/Statistics/StatisticsWebmaster');
const StatisticsAdvertiser = () => import('@/views/Statistics/StatisticsAdvertiser');
const Campaigns = () => import('@/views/Campaigns/Campaigns');
const CampaignCreate = () => import('@/views/Campaigns/CampaignCreate');
const CampaignEdit = () => import('@/views/Campaigns/CampaignEdit');
const Creatives = () => import('@/views/Creatives/Creatives');
const CreativeCreate = () => import('@/views/Creatives/CreativeCreate');
const CreativeEdit = () => import('@/views/Creatives/CreativeEdit');
const CreativeMediaCreate = () => import('@/views/Creatives/CreativeMedia/CreativeMediaCreate');
const AdminSites = () => import('@/views/Admin/Sites/Sites');
const AdvertiserEdit = () => import('@/views/Admin/Advertisers/AdvertiserEdit');
const AdminCreatives = () => import('@/views/Admin/Creatives/Creatives');
const AdminStatistics = () => import('@/views/Admin/Statistics/Statistics');
const Advertisers = () => import('@/views/Admin/Advertisers/Advertisers');
const Webmasters = () => import('@/views/Admin/Webmasters/Webmasters');

Vue.use(Router);

const webmaster = 'ROLE_WEBMASTER';
const advertiser = 'ROLE_ADVERTISER';
const administrator = 'ROLE_ADMINISTRATOR';

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/auth',
      name: 'main',
      component: TheContainer,
      children: [
        {
          path: 'auth',
          name: 'auth',
          component: Auth,
        },
        {
          path: 'profile-webmaster',
          name: 'profile-webmaster',
          meta: { accessLevel: webmaster },
          component: ProfileWebmaster,
        },
        {
          path: 'sites',
          name: 'sites',
          meta: { accessLevel: webmaster },
          component: Sites,
        },
        {
          path: 'sites/site-create',
          name: 'site-create',
          meta: { accessLevel: webmaster },
          component: SiteCreate,
        },
        {
          path: 'sites/site-edit/:id',
          name: 'site-edit',
          meta: { accessLevel: webmaster },
          component: SiteEdit,
        },
        {
          path: 'blocks',
          name: 'blocks',
          meta: { accessLevel: webmaster },
          component: Blocks,
        },
        {
          path: 'blocks/block-create',
          name: 'block-create',
          meta: { accessLevel: webmaster },
          component: BlockCreate,
        },
        {
          path: 'blocks/block-edit/:id',
          name: 'block-edit',
          meta: { accessLevel: webmaster },
          component: BlockEdit,
        },
        {
          path: 'statistics/webmaster',
          name: 'statistics-webmaster',
          meta: { accessLevel: webmaster },
          component: StatisticsWebmaster,
        },
        {
          path: 'profile-advertiser',
          name: 'profile-advertiser',
          meta: { accessLevel: advertiser },
          component: ProfileAdvertiser,
        },
        {
          path: 'campaigns',
          name: 'campaigns',
          meta: { accessLevel: advertiser },
          component: Campaigns,
        },
        {
          path: 'campaigns/campaign-create',
          name: 'campaign-create',
          meta: { accessLevel: advertiser },
          component: CampaignCreate,
        },
        {
          path: 'campaigns/campaign-edit/:id',
          name: 'campaign-edit',
          meta: { accessLevel: advertiser },
          component: CampaignEdit,
        },
        {
          path: 'creatives',
          name: 'creatives',
          meta: { accessLevel: advertiser },
          component: Creatives,
        },
        {
          path: 'creatives/creative-create',
          name: 'creative-create',
          meta: { accessLevel: advertiser },
          component: CreativeCreate,
        },
        {
          path: 'creatives/creative-edit/:id',
          name: 'creative-edit',
          meta: { accessLevel: advertiser },
          component: CreativeEdit,
        },
        {
          path: 'creatives/creative-edit/:id/media-create',
          name: 'creative-media-create',
          meta: { accessLevel: advertiser },
          component: CreativeMediaCreate,
        },
        {
          path: 'statistics/advertiser',
          name: 'statistics-advertiser',
          meta: { accessLevel: advertiser },
          component: StatisticsAdvertiser,
        },
        {
          path: 'admin/advertisers',
          name: 'admin-advertisers',
          meta: { accessLevel: administrator },
          component: Advertisers,
        },
        {
          path: '/admin/advertisers/:id',
          name: 'admin-advertisers-edit',
          meta: { accessLevel: administrator },
          component: AdvertiserEdit,
        },
        {
          path: 'admin/webmasters',
          name: 'admin-webmasters',
          meta: { accessLevel: administrator },
          component: Webmasters,
        },
        {
          path: '/admin/sites',
          name: 'admin-sites',
          meta: { accessLevel: administrator },
          component: AdminSites,
        },
        {
          path: 'admin/creatives',
          name: 'admin-creatives',
          meta: { accessLevel: administrator },
          component: AdminCreatives,
        },
        {
          path: 'admin/statistics',
          name: 'admin-statistics',
          meta: { accessLevel: administrator },
          component: AdminStatistics,
        },
      ],
    },
    {
      path: '/register',
      name: 'register',
      meta: { accessible: true },
      component: Register,
    },
    {
      path: '/login',
      name: 'login',
      meta: { accessible: true },
      component: Login,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (!to.meta.accessible) {
    const accessToken = getItem('accessToken');

    if (!accessToken) {
      next({ name: 'login' });
    } else if (to.meta.accessLevel) {
      const roles = getItem('roles');

      if (roles && roles.includes(to.meta.accessLevel)) {
        next();
      } else {
        next('/');
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
