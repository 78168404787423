import {
  GET_SITES_LIST_START,
  GET_SITES_LIST_SUCCESS,
  GET_SITES_LIST_FAILURE,
  EDIT_SITE_START,
  EDIT_SITE_SUCCESS,
  EDIT_SITE_FAILURE,
  GET_CREATIVES_LIST_START,
  GET_CREATIVES_LIST_SUCCESS,
  GET_CREATIVES_LIST_FAILURE,
  EDIT_CREATIVE_START,
  EDIT_CREATIVE_SUCCESS,
  EDIT_CREATIVE_FAILURE,
  GET_STATISTICS_START,
  GET_STATISTICS_SUCCESS,
  GET_STATISTICS_FAILURE,
  GET_FULL_WIDGETS_LIST_START,
  GET_FULL_WIDGETS_LIST_SUCCESS,
  GET_FULL_WIDGETS_LIST_FAILURE,
  GET_FULL_CREATIVES_LIST_START,
  GET_FULL_CREATIVES_LIST_SUCCESS,
  GET_FULL_CREATIVES_LIST_FAILURE,
  GET_FULL_SITES_LIST_START,
  GET_FULL_SITES_LIST_SUCCESS,
  GET_FULL_SITES_LIST_FAILURE,
  GET_FULL_ADVERTISERS_LIST_START,
  GET_FULL_ADVERTISERS_LIST_SUCCESS,
  GET_FULL_ADVERTISERS_LIST_FAILURE,
  GET_ADVERTISERS_LIST_START,
  GET_ADVERTISERS_LIST_SUCCESS,
  GET_ADVERTISERS_LIST_FAILURE,
  GET_WEBMASTERS_LIST_START,
  GET_WEBMASTERS_LIST_SUCCESS,
  GET_WEBMASTERS_LIST_FAILURE,
  GET_USER_TOKEN_START,
  GET_USER_TOKEN_SUCCESS,
  GET_USER_TOKEN_FAILURE,
  EDIT_ADVERTISER_PROFILE_START,
  EDIT_ADVERTISER_PROFILE_SUCCESS,
  EDIT_ADVERTISER_PROFILE_FAILURE,
  GET_USER_PROFILE_START,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
} from '@/store/mutation-types/admin';

export default {
  [GET_SITES_LIST_START](state) {
    state.sites.isLoading = true;
    state.sites.list = [];
    state.sites.total = null;
    state.sites.error = false;
  },
  [GET_SITES_LIST_SUCCESS](state, payload) {
    state.sites.isLoading = false;
    state.sites.list = payload.items;
    state.sites.total = payload.total;
  },
  [GET_SITES_LIST_FAILURE](state) {
    state.sites.isLoading = false;
    state.sites.error = true;
  },
  [EDIT_SITE_START](state) {
    state.sites.isSending = true;
    state.sites.error = null;
  },
  [EDIT_SITE_SUCCESS](state) {
    state.sites.isSending = false;
  },
  [EDIT_SITE_FAILURE](state) {
    state.sites.isSending = false;
  },
  [GET_CREATIVES_LIST_START](state) {
    state.creatives.isLoading = true;
    state.creatives.list = [];
    state.creatives.total = null;
    state.creatives.error = false;
  },
  [GET_CREATIVES_LIST_SUCCESS](state, payload) {
    state.creatives.isLoading = false;
    state.creatives.list = payload.items;
    state.creatives.total = payload.total;
  },
  [GET_CREATIVES_LIST_FAILURE](state) {
    state.creatives.isLoading = false;
    state.creatives.error = true;
  },
  [EDIT_CREATIVE_START](state) {
    state.creatives.isSending = true;
    state.creatives.error = null;
  },
  [EDIT_CREATIVE_SUCCESS](state) {
    state.creatives.isSending = false;
  },
  [EDIT_CREATIVE_FAILURE](state) {
    state.creatives.isSending = false;
  },
  [GET_STATISTICS_START](state) {
    state.statistics.isLoading = true;
    state.statistics.statistics = [];
    state.statistics.total = null;
    state.statistics.error = null;
  },
  [GET_STATISTICS_SUCCESS](state, payload) {
    state.statistics.isLoading = false;
    state.statistics.list = payload.items;
    state.statistics.total = payload.total;
  },
  [GET_STATISTICS_FAILURE](state) {
    state.statistics.isLoading = false;
    state.statistics.error = true;
  },
  [GET_FULL_WIDGETS_LIST_START](state) {
    state.allWidgets.isLoading = true;
    state.allWidgets.list = [];
    state.allWidgets.error = false;
  },
  [GET_FULL_WIDGETS_LIST_SUCCESS](state, payload) {
    state.allWidgets.isLoading = false;
    state.allWidgets.list = payload;
  },
  [GET_FULL_WIDGETS_LIST_FAILURE](state) {
    state.allWidgets.isLoading = false;
    state.allWidgets.error = true;
  },
  [GET_FULL_CREATIVES_LIST_START](state) {
    state.allCreatives.isLoading = true;
    state.allCreatives.list = [];
    state.allCreatives.error = false;
  },
  [GET_FULL_CREATIVES_LIST_SUCCESS](state, payload) {
    state.allCreatives.isLoading = false;
    state.allCreatives.list = payload;
  },
  [GET_FULL_CREATIVES_LIST_FAILURE](state) {
    state.allCreatives.isLoading = false;
    state.allCreatives.error = true;
  },
  [GET_FULL_SITES_LIST_START](state) {
    state.allSites.isLoading = true;
    state.allSites.list = [];
    state.allSites.error = false;
  },
  [GET_FULL_SITES_LIST_SUCCESS](state, payload) {
    state.allSites.isLoading = false;
    state.allSites.list = payload;
  },
  [GET_FULL_SITES_LIST_FAILURE](state) {
    state.allSites.isLoading = false;
    state.allSites.error = true;
  },
  [GET_FULL_ADVERTISERS_LIST_START](state) {
    state.allAdvertisers.isLoading = true;
    state.allAdvertisers.list = [];
    state.allAdvertisers.error = false;
  },
  [GET_FULL_ADVERTISERS_LIST_SUCCESS](state, payload) {
    state.allAdvertisers.isLoading = false;
    state.allAdvertisers.list = payload;
  },
  [GET_FULL_ADVERTISERS_LIST_FAILURE](state) {
    state.allAdvertisers.isLoading = false;
    state.allAdvertisers.error = true;
  },
  [GET_ADVERTISERS_LIST_START](state) {
    state.advertisers.isLoading = true;
    state.advertisers.list = [];
    state.advertisers.total = null;
    state.advertisers.error = false;
  },
  [GET_ADVERTISERS_LIST_SUCCESS](state, payload) {
    state.advertisers.isLoading = false;
    state.advertisers.list = payload.items;
    state.advertisers.total = payload.total;
  },
  [GET_ADVERTISERS_LIST_FAILURE](state) {
    state.advertisers.isLoading = false;
    state.advertisers.error = true;
  },
  [GET_WEBMASTERS_LIST_START](state) {
    state.webmasters.isLoading = true;
    state.webmasters.list = [];
    state.webmasters.total = null;
    state.webmasters.error = false;
  },
  [GET_WEBMASTERS_LIST_SUCCESS](state, payload) {
    state.webmasters.isLoading = false;
    state.webmasters.list = payload.items;
    state.webmasters.total = payload.total;
  },
  [GET_WEBMASTERS_LIST_FAILURE](state) {
    state.webmasters.isLoading = false;
    state.webmasters.error = true;
  },
  [GET_USER_TOKEN_START](state) {
    state.userToken.isSending = true;
    state.userToken.error = null;
  },
  [GET_USER_TOKEN_SUCCESS](state) {
    state.userToken.isSending = false;
  },
  [GET_USER_TOKEN_FAILURE](state) {
    state.userToken.isSending = false;
  },
  [EDIT_ADVERTISER_PROFILE_START](state) {
    state.advertisers.isSending = true;
    state.advertisers.error = null;
  },
  [EDIT_ADVERTISER_PROFILE_SUCCESS](state) {
    state.advertisers.isSending = false;
  },
  [EDIT_ADVERTISER_PROFILE_FAILURE](state) {
    state.advertisers.isSending = false;
  },
  [GET_USER_PROFILE_START](state) {
    state.userProfile.isLoading = true;
    state.userProfile.info = null;
    state.userProfile.error = false;
  },
  [GET_USER_PROFILE_SUCCESS](state, payload) {
    state.userProfile.isLoading = false;
    state.userProfile.info = payload;
  },
  [GET_USER_PROFILE_FAILURE](state) {
    state.userProfile.isLoading = false;
    state.userProfile.error = true;
  },
};
