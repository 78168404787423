import axios from '@/api/axios';

const getBlocksList = (params) => {
  return axios.post('/webmaster/widgets', params);
};

const getSiteBlocksList = (id, params) => {
  return axios.get(`/webmaster/site/${id}/widgets`, { params });
};

const createBlock = (id, formData) => {
  return axios.post(`/webmaster/site/${id}/widget`, formData);
};

const getBlock = (id) => {
  return axios.get(`/webmaster/widget/${id}`);
};

const editBlock = (id, formData) => {
  return axios.post(`/webmaster/widget/${id}/update`, formData);
};

const getBlockCode = (id) => {
  return axios.get(`/webmaster/widget/${id}/code`);
};

export default {
  getBlocksList,
  getSiteBlocksList,
  createBlock,
  getBlock,
  editBlock,
  getBlockCode,
};
