import axios from 'axios';
import { getItem } from '@/helpers/persistance-storage';

axios.defaults.baseURL = process.env.VUE_APP_API_HOST;

axios.interceptors.request.use((config) => {
  const accessToken = getItem('accessToken');

  if (accessToken?.token) {
    config.headers['X-Auth-Token'] = accessToken.token;
  }

  return config;
});

export default axios;
