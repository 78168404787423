export const GET_SITES_LIST = 'GET_SITES_LIST';
export const EDIT_SITE = 'EDIT_SITE';
export const GET_CREATIVES_LIST = 'GET_CREATIVES_LIST';
export const EDIT_CREATIVE = 'EDIT_CREATIVE';
export const GET_STATISTICS = 'GET_STATISTICS';
export const GET_FULL_WIDGETS_LIST = 'GET_FULL_WIDGETS_LIST';
export const GET_FULL_CREATIVES_LIST = 'GET_FULL_CREATIVES_LIST';
export const GET_FULL_SITES_LIST = 'GET_FULL_SITES_LIST';
export const GET_FULL_ADVERTISERS_LIST = 'GET_FULL_ADVERTISERS_LIST';
export const GET_ADVERTISERS_LIST = 'GET_ADVERTISERS_LIST';
export const GET_WEBMASTERS_LIST = 'GET_WEBMASTERS_LIST';
export const GET_USER_TOKEN = 'GET_USER_TOKEN';
export const EDIT_ADVERTISER_PROFILE = 'EDIT_ADVERTISER_PROFILE';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
