import userProfileApi from '@/api/profile';

import {
  GET_USER_PROFILE_START,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
} from '@/store/mutation-types/profile';

import { GET_USER_PROFILE } from '@/store/action-types/profile';

const state = {
  data: null,
  isLoading: false,
  error: null,
};

const mutations = {
  [GET_USER_PROFILE_START](state) {
    state.isLoading = true;
    state.data = null;
    state.error = false;
  },
  [GET_USER_PROFILE_SUCCESS](state, payload) {
    state.isLoading = false;
    state.data = payload;
  },
  [GET_USER_PROFILE_FAILURE](state) {
    state.isLoading = false;
    state.error = true;
  },
};

const actions = {
  [GET_USER_PROFILE]({ commit }, apiUrl) {
    return new Promise((resolve) => {
      commit(GET_USER_PROFILE_START);
      userProfileApi
        .getUserProfile(apiUrl)
        .then((userProfile) => {
          commit(GET_USER_PROFILE_SUCCESS, userProfile);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_USER_PROFILE_FAILURE);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
