export default {
  siteCategories(state) {
    return state.siteCategories.list.map((item) => {
      return {
        value: item.category,
        label: item.category,
      };
    });
  },
  creativeTypes(state) {
    return state.creativeTypes.list.map((item) => {
      return {
        name: item.name,
        type: item.type,
      };
    });
  },
  formatTypes(state) {
    return state.formatTypes.list.map((item) => {
      return {
        name: item.name,
        type: item.type,
      };
    });
  },
  platforms(state) {
    return state.platforms.list.map((item) => {
      return {
        name: item.name,
        id: item.id,
      };
    });
  },
  browsers(state) {
    return state.browsers.list.map((item) => {
      return {
        name: item.name,
        id: item.id,
      };
    });
  },
  countries(state) {
    return state.countries.list
      .map((item) => {
        return {
          name: item.name,
          id: item.code,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  },
  languages(state) {
    return state.languages.list
      .map((item) => {
        return {
          name: item.name,
          id: item.id,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  },
  linkTypes(state) {
    return state.linkTypes.list.map((item) => {
      return {
        name: item.name,
        id: item.id,
      };
    });
  },
};
