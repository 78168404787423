import axios from '@/api/axios';

const getCampaignList = (params) => {
  return axios.get('/advertiser/campaign', { params });
};

const createCampaign = (formData) => {
  return axios.post('/advertiser/campaign/create', formData);
};

const cloneCampaign = (id, withCreative) => {
  return axios.post(`/advertiser/campaign/${id}/clone`, null, { params: { creatives: withCreative } });
};

const createCampaignCreative = (id, model) => {
  return axios.post(`/advertiser/campaign/${id}/creative`, model);
};

const getCampaign = (id) => {
  return axios.get(`/advertiser/campaign/${id}`);
};

const editCampaign = (id, formData) => {
  return axios.post(`/advertiser/campaign/${id}/update`, formData);
};

const getDomainList = () => {
  return axios.get('/advertiser/domains');
};

const getWebmastersList = () => {
  return axios.get('/advertiser/webmasters');
};

export default {
  getCampaignList,
  createCampaign,
  cloneCampaign,
  createCampaignCreative,
  getCampaign,
  editCampaign,
  getDomainList,
  getWebmastersList,
};
