import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import ui from './modules/ui';
import auth from './modules/auth';
import profile from './modules/profile';
import roles from './modules/roles';
import sites from './modules/sites';
import dictionary from './modules/dictionary/';
import blocks from './modules/blocks';
import statistics from './modules/statistics';
import campaigns from './modules/campaigns/';
import creatives from './modules/creatives';
import admin from './modules/admin/';

const store = new Vuex.Store({
  strict: process.env.VUE_APP_ENVIRONMENT !== 'production',
  modules: {
    ui,
    auth,
    profile,
    roles,
    sites,
    dictionary,
    blocks,
    statistics,
    campaigns,
    creatives,
    admin,
  },
});

export default store;
