import blocksApi from '@/api/blocks';

import {
  GET_BLOCKS_LIST_START,
  GET_BLOCKS_LIST_SUCCESS,
  GET_BLOCKS_LIST_FAILURE,
  GET_SITE_BLOCKS_LIST_START,
  GET_SITE_BLOCKS_LIST_SUCCESS,
  GET_SITE_BLOCKS_LIST_FAILURE,
  CREATE_BLOCK_START,
  CREATE_BLOCK_SUCCESS,
  CREATE_BLOCK_FAILURE,
  GET_BLOCK_START,
  GET_BLOCK_SUCCESS,
  GET_BLOCK_FAILURE,
  EDIT_BLOCK_START,
  EDIT_BLOCK_SUCCESS,
  EDIT_BLOCK_FAILURE,
  GET_BLOCK_CODE_START,
  GET_BLOCK_CODE_SUCCESS,
  GET_BLOCK_CODE_FAILURE,
} from '@/store/mutation-types/blocks';

import {
  GET_BLOCKS_LIST,
  GET_SITE_BLOCKS_LIST,
  CREATE_BLOCK,
  GET_BLOCK,
  EDIT_BLOCK,
  GET_BLOCK_CODE,
} from '@/store/action-types/blocks';

const state = {
  blocksList: [],
  block: null,
  code: null,
  total: null,
  isLoading: false,
  error: null,
  isSending: false,
};

const mutations = {
  [GET_BLOCKS_LIST_START](state) {
    state.isLoading = true;
    state.blocksList = [];
    state.total = null;
    state.error = false;
  },
  [GET_BLOCKS_LIST_SUCCESS](state, payload) {
    state.isLoading = false;
    state.blocksList = payload.items;
    state.total = payload.total;
  },
  [GET_BLOCKS_LIST_FAILURE](state) {
    state.isLoading = false;
    state.error = true;
  },
  [GET_SITE_BLOCKS_LIST_START](state) {
    state.isLoading = true;
    state.blocksList = [];
    state.total = null;
    state.error = false;
  },
  [GET_SITE_BLOCKS_LIST_SUCCESS](state, payload) {
    state.isLoading = false;
    state.blocksList = payload.items;
    state.total = payload.total;
  },
  [GET_SITE_BLOCKS_LIST_FAILURE](state) {
    state.isLoading = false;
    state.error = true;
  },
  [CREATE_BLOCK_START](state) {
    state.isSending = true;
    state.error = null;
  },
  [CREATE_BLOCK_SUCCESS](state) {
    state.isSending = false;
  },
  [CREATE_BLOCK_FAILURE](state) {
    state.isSending = false;
  },
  [GET_BLOCK_START](state) {
    state.isLoading = true;
    state.block = null;
    state.error = false;
  },
  [GET_BLOCK_SUCCESS](state, payload) {
    state.isLoading = false;
    state.block = payload;
  },
  [GET_BLOCK_FAILURE](state) {
    state.isLoading = false;
    state.error = true;
  },
  [EDIT_BLOCK_START](state) {
    state.isSending = true;
  },
  [EDIT_BLOCK_SUCCESS](state) {
    state.isSending = false;
  },
  [EDIT_BLOCK_FAILURE](state) {
    state.isSending = false;
  },
  [GET_BLOCK_CODE_START](state) {
    state.isSending = true;
    state.code = null;
  },
  [GET_BLOCK_CODE_SUCCESS](state, payload) {
    state.isSending = false;
    state.code = payload;
  },
  [GET_BLOCK_CODE_FAILURE](state) {
    state.isSending = false;
  },
};

const actions = {
  [GET_BLOCKS_LIST]({ commit }, payload) {
    return new Promise((resolve) => {
      commit(GET_BLOCKS_LIST_START);
      blocksApi
        .getBlocksList(payload)
        .then((response) => {
          commit(GET_BLOCKS_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_BLOCKS_LIST_FAILURE);
        });
    });
  },
  [GET_SITE_BLOCKS_LIST]({ commit }, { id, params }) {
    return new Promise((resolve) => {
      commit(GET_SITE_BLOCKS_LIST_START);
      blocksApi
        .getSiteBlocksList(id, params)
        .then((response) => {
          commit(GET_SITE_BLOCKS_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_SITE_BLOCKS_LIST_FAILURE);
        });
    });
  },
  [CREATE_BLOCK]({ commit }, { id, formData }) {
    return new Promise((resolve, reject) => {
      commit(CREATE_BLOCK_START);
      blocksApi
        .createBlock(id, formData)
        .then(() => {
          commit(CREATE_BLOCK_SUCCESS);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(CREATE_BLOCK_FAILURE);
          reject();
        });
    });
  },
  [GET_BLOCK]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(GET_BLOCK_START);
      blocksApi
        .getBlock(payload)
        .then((block) => {
          commit(GET_BLOCK_SUCCESS, block.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_BLOCK_FAILURE);
          reject();
        });
    });
  },
  [EDIT_BLOCK]({ commit }, { id, formData }) {
    return new Promise((resolve, reject) => {
      commit(EDIT_BLOCK_START);
      blocksApi
        .editBlock(id, formData)
        .then(() => {
          commit(EDIT_BLOCK_SUCCESS);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(EDIT_BLOCK_FAILURE);
          reject();
        });
    });
  },
  [GET_BLOCK_CODE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(GET_BLOCK_CODE_START);
      blocksApi
        .getBlockCode(payload)
        .then((code) => {
          commit(GET_BLOCK_CODE_SUCCESS, code.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_BLOCK_CODE_FAILURE);
          reject();
        });
    });
  },
};

const getters = {
  blockList(state) {
    return state.blocksList
      .map((block) => {
        return {
          label: block.name,
          value: block.id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
