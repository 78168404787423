import axios from '@/api/axios';

const getSitesList = (params) => {
  return axios.get('/admin/sites', { params });
};

const editSite = (id, site) => {
  return axios.post(`/admin/site/${id}/update`, site);
};

const getCreativesList = (params) => {
  return axios.get('/admin/creatives', { params });
};

const editCreative = (id, creative) => {
  return axios.post(`/admin/creative/${id}/update`, creative);
};

const getFullWidgetsList = () => {
  return axios.get('/admin/autocomplete/widgets');
};

const getFullSitesList = () => {
  return axios.get('/admin/autocomplete/sites');
};

const getFullCreativesList = () => {
  return axios.get('/admin/autocomplete/creatives');
};

const getFullAdvertisersList = () => {
  return axios.get('/admin/autocomplete/advertisers');
};

const getStatistics = (filters) => {
  return axios.post('/admin/statistic', filters);
};

const getAdvertisersList = (params) => {
  return axios.get('/admin/advertisers', { params });
};

const getWebmastersList = (params) => {
  return axios.get('/admin/webmasters', { params });
};

const getUserToken = (userId) => {
  return axios.get(`/admin/user/${userId}/token`);
};

const editAdvertiserProfile = (id, formData) => {
  return axios.post(`/admin/advertiser/${id}/update`, formData);
};

const getUserProfile = (id) => {
  return axios.get(`/admin/user/${id}/info`);
};

export default {
  getSitesList,
  editSite,
  getCreativesList,
  editCreative,
  getStatistics,
  getFullWidgetsList,
  getFullSitesList,
  getFullCreativesList,
  getFullAdvertisersList,
  getAdvertisersList,
  getUserToken,
  getWebmastersList,
  editAdvertiserProfile,
  getUserProfile,
};
