import axios from '@/api/axios';

const getCreativesList = (params) => {
  return axios.post('/advertiser/creatives', params);
};

const getCampaignCreativesList = (id, params) => {
  return axios.get(`/advertiser/campaing/${id}/creatives`, { params });
};

const createCreative = (id, formData) => {
  return axios.post(`/advertiser/campaign/${id}/creative`, formData);
};

const getCreative = (id) => {
  return axios.get(`/advertiser/creative/${id}`);
};

const editCreative = (id, formData) => {
  return axios.post(`/advertiser/creative/${id}/update`, formData);
};

const uploadMedia = (id, formData) => {
  return axios.post(`/advertiser/creative/${id}/media`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const editCreativeMedia = (id, model) => {
  return axios.post(`/advertiser/media/${id}/update`, model);
};

export default {
  getCreativesList,
  getCampaignCreativesList,
  createCreative,
  getCreative,
  editCreative,
  uploadMedia,
  editCreativeMedia,
};
