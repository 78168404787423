import campaignsApi from '@/api/campaigns';

import {
  GET_CAMPAIGN_LIST_START,
  GET_CAMPAIGN_LIST_SUCCESS,
  GET_CAMPAIGN_LIST_FAILURE,
  CREATE_CAMPAIGN_START,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,
  CLONE_CAMPAIGN_START,
  CLONE_CAMPAIGN_SUCCESS,
  CLONE_CAMPAIGN_FAILURE,
  CREATE_CAMPAIGN_CREATIVE_START,
  CREATE_CAMPAIGN_CREATIVE_SUCCESS,
  CREATE_CAMPAIGN_CREATIVE_FAILURE,
  GET_CAMPAIGN_START,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_FAILURE,
  EDIT_CAMPAIGN_START,
  EDIT_CAMPAIGN_SUCCESS,
  EDIT_CAMPAIGN_FAILURE,
  GET_DOMAIN_LIST_START,
  GET_DOMAIN_LIST_SUCCESS,
  GET_DOMAIN_LIST_FAILURE,
  GET_WEBMASTERS_LIST_START,
  GET_WEBMASTERS_LIST_SUCCESS,
  GET_WEBMASTERS_LIST_FAILURE,
} from '@/store/mutation-types/campaigns';

import {
  GET_CAMPAIGN_LIST,
  CREATE_CAMPAIGN,
  CLONE_CAMPAIGN,
  CREATE_CAMPAIGN_CREATIVE,
  GET_CAMPAIGN,
  EDIT_CAMPAIGN,
  GET_DOMAIN_LIST,
  GET_WEBMASTERS_LIST,
} from '@/store/action-types/campaigns';

export default {
  [GET_CAMPAIGN_LIST]({ commit }, payload) {
    return new Promise((resolve) => {
      commit(GET_CAMPAIGN_LIST_START);
      campaignsApi
        .getCampaignList(payload)
        .then((response) => {
          commit(GET_CAMPAIGN_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_CAMPAIGN_LIST_FAILURE);
        });
    });
  },
  [CREATE_CAMPAIGN]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(CREATE_CAMPAIGN_START);
      campaignsApi
        .createCampaign(payload)
        .then(() => {
          commit(CREATE_CAMPAIGN_SUCCESS);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(CREATE_CAMPAIGN_FAILURE);
          reject();
        });
    });
  },
  [GET_CAMPAIGN]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(GET_CAMPAIGN_START);
      campaignsApi
        .getCampaign(payload)
        .then((site) => {
          commit(GET_CAMPAIGN_SUCCESS, site.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_CAMPAIGN_FAILURE);
          reject();
        });
    });
  },
  [EDIT_CAMPAIGN]({ commit }, { id, formData }) {
    return new Promise((resolve, reject) => {
      commit(EDIT_CAMPAIGN_START);
      campaignsApi
        .editCampaign(id, formData)
        .then(() => {
          commit(EDIT_CAMPAIGN_SUCCESS);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(EDIT_CAMPAIGN_FAILURE);
          reject();
        });
    });
  },
  [CLONE_CAMPAIGN]({ commit }, { id, withCreative }) {
    return new Promise((resolve, reject) => {
      commit(CLONE_CAMPAIGN_START);
      campaignsApi
        .cloneCampaign(id, withCreative)
        .then(() => {
          commit(CLONE_CAMPAIGN_SUCCESS);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(CLONE_CAMPAIGN_FAILURE);
          reject();
        });
    });
  },
  [CREATE_CAMPAIGN_CREATIVE]({ commit }, { id, model }) {
    return new Promise((resolve, reject) => {
      commit(CREATE_CAMPAIGN_CREATIVE_START);
      campaignsApi
        .createCampaignCreative(id, model)
        .then(() => {
          commit(CREATE_CAMPAIGN_CREATIVE_SUCCESS);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(CREATE_CAMPAIGN_CREATIVE_FAILURE);
          reject();
        });
    });
  },
  [GET_DOMAIN_LIST]({ commit }) {
    return new Promise((resolve) => {
      commit(GET_DOMAIN_LIST_START);
      campaignsApi
        .getDomainList()
        .then((response) => {
          commit(GET_DOMAIN_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_DOMAIN_LIST_FAILURE);
        });
    });
  },
  [GET_WEBMASTERS_LIST]({ commit }) {
    return new Promise((resolve) => {
      commit(GET_WEBMASTERS_LIST_START);
      campaignsApi
        .getWebmastersList()
        .then((response) => {
          commit(GET_WEBMASTERS_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_WEBMASTERS_LIST_FAILURE);
        });
    });
  },
};
