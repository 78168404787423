export default {
  creativeList(state) {
    return state.allCreatives.list
      .map((creative) => {
        return {
          label: creative.name,
          value: creative.id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  },
  siteList(state) {
    return state.allSites.list
      .map((site) => {
        return {
          label: site.name,
          value: site.id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  },
  advertiserList(state) {
    return state.allAdvertisers.list
      .map((advertiser) => {
        return {
          label: advertiser.name,
          value: advertiser.id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  },
  widgetList(state) {
    return state.allWidgets.list
      .map((widget) => {
        return {
          label: widget.name,
          value: widget.id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  },
};
