export const GET_SITES_LIST_START = 'GET_SITES_LIST_START';
export const GET_SITES_LIST_SUCCESS = 'GET_SITES_LIST_SUCCESS';
export const GET_SITES_LIST_FAILURE = 'GET_SITES_LIST_FAILURE';
export const CREATE_SITE_START = 'CREATE_SITE_START';
export const CREATE_SITE_SUCCESS = 'CREATE_SITE_SUCCESS';
export const CREATE_SITE_FAILURE = 'CREATE_SITE_FAILURE';
export const GET_SITE_START = 'GET_SITE_START';
export const GET_SITE_SUCCESS = 'GET_SITE_SUCCESS';
export const GET_SITE_FAILURE = 'GET_SITE_FAILURE';
export const EDIT_SITE_START = 'EDIT_SITE_START';
export const EDIT_SITE_SUCCESS = 'EDIT_SITE_SUCCESS';
export const EDIT_SITE_FAILURE = 'EDIT_SITE_FAILURE';
