import {
  cilApplicationsSettings,
  cilDrop,
  cilPuzzle,
  cilMoon,
  cilSpeedometer,
  cilSun,
  cilLockLocked,
  cilUser,
  cilPlus,
  cilChevronLeft,
  cilPencil,
  cilTrash,
  cilCode,
  cilChart,
  cilShareAlt,
  cilCopy,
  cilMediaPause,
  cilMediaPlay,
  cilInfinity,
  cilClone,
  cibAtom,
  cilThumbUp,
  cilThumbDown,
  cilAudio,
  cilArrowThickToLeft,
} from '@coreui/icons';
import { logo } from './logo';

export const iconsSet = Object.assign(
  {},
  {
    cilApplicationsSettings,
    cilDrop,
    cilPuzzle,
    cilMoon,
    cilSpeedometer,
    cilSun,
    cilLockLocked,
    cilUser,
    logo,
    cilPlus,
    cilChevronLeft,
    cilPencil,
    cilTrash,
    cilCode,
    cilChart,
    cilShareAlt,
    cilCopy,
    cilMediaPause,
    cilMediaPlay,
    cilInfinity,
    cilClone,
    cibAtom,
    cilThumbUp,
    cilThumbDown,
    cilAudio,
    cilArrowThickToLeft,
  }
);
