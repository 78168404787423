export default {
  campaignList(state, getters, rootState, rootGetters) {
    const platforms = rootGetters['dictionary/platforms'];
    const browsers = rootGetters['dictionary/browsers'];
    const countries = rootGetters['dictionary/countries'];
    const languages = rootGetters['dictionary/languages'];
    const domains = getters.domains;

    const normalizeIds = (category, itemId) => {
      const findedElem = category.find((el) => el.id === itemId);

      if (findedElem) {
        return findedElem;
      } else {
        return itemId;
      }
    };

    const mapList = (list, category) => {
      return list ? list.split(',').map((itemId) => normalizeIds(category, itemId)) : list;
    };

    return state.campaignList.map((campaign) => {
      return {
        ...campaign,
        platform: mapList(campaign.platform, platforms),
        browser: mapList(campaign.browser, browsers),
        country: mapList(campaign.country, countries),
        language: mapList(campaign.language, languages),
        siteWhiteList: mapList(campaign.siteWhiteList, domains),
      };
    });
  },
  campaign(state) {
    const campaign = state.campaign;

    if (!campaign) {
      return campaign;
    }

    const platform = campaign?.platform?.length > 0 ? campaign.platform.split(',') : null;
    const browser = campaign?.browser?.length > 0 ? campaign.browser.split(',') : null;
    const country = campaign?.country?.length > 0 ? campaign.country.split(',') : null;
    const language = campaign?.language?.length > 0 ? campaign.language.split(',') : null;
    const siteWhiteList = campaign?.siteWhiteList?.length > 0 ? campaign.siteWhiteList.split(',') : null;

    return {
      ...campaign,
      platform,
      browser,
      country,
      language,
      siteWhiteList,
    };
  },
  mappedCampaignList(state) {
    return state.campaignList
      .map((campaign) => {
        return {
          label: campaign.name,
          value: campaign.id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  },
  domains(state) {
    return state.domainList.map((item) => {
      return {
        name: item.name,
        id: String(item.id),
      };
    });
  },
  webmasters(state) {
    return state.webmastersList.map((item) => {
      return {
        name: item.name,
        id: String(item.id),
      };
    });
  },
};
