import axios from '@/api/axios';

const getSitesList = (params) => {
  return axios.get(`/webmaster/sites`, { params });
};

const createSite = (formData) => {
  return axios.post('/webmaster/site/create', formData);
};

const getSite = (id) => {
  return axios.get(`/webmaster/site/${id}`);
};

const editSite = (id, formData) => {
  return axios.post(`/webmaster/site/${id}/update`, formData);
};

export default {
  getSitesList,
  createSite,
  getSite,
  editSite,
};
