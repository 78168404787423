import axios from '@/api/axios';

const getWebmasterStatistics = (formData) => {
  return axios.post('/webmaster/statistic', formData);
};

const getAdvertiserStatistics = (formData) => {
  return axios.post('/advertiser/statistic', formData);
};

export default {
  getWebmasterStatistics,
  getAdvertiserStatistics,
};
