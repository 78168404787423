export const GET_SITE_CATEGORIES_START = 'GET_SITE_CATEGORIES_START';
export const GET_SITE_CATEGORIES_SUCCESS = 'GET_SITE_CATEGORIES_SUCCESS';
export const GET_SITE_CATEGORIES_FAILURE = 'GET_SITE_CATEGORIES_FAILURE';
export const GET_CREATIVE_TYPES_START = 'GET_CREATIVE_TYPES_START';
export const GET_CREATIVE_TYPES_SUCCESS = 'GET_CREATIVE_TYPES_SUCCESS';
export const GET_CREATIVE_TYPES_FAILURE = 'GET_CREATIVE_TYPES_FAILURE';
export const GET_FORMAT_TYPES_START = 'GET_FORMAT_TYPES_START';
export const GET_FORMAT_TYPES_SUCCESS = 'GET_FORMAT_TYPES_SUCCESS';
export const GET_FORMAT_TYPES_FAILURE = 'GET_FORMAT_TYPES_FAILURE';
export const GET_PLATFORM_LIST_START = 'GET_PLATFORM_LIST_START';
export const GET_PLATFORM_LIST_SUCCESS = 'GET_PLATFORM_LIST_SUCCESS';
export const GET_PLATFORM_LIST_FAILURE = 'GET_PLATFORM_LIST_FAILURE';
export const GET_BROWSER_LIST_START = 'GET_BROWSER_LIST_START';
export const GET_BROWSER_LIST_SUCCESS = 'GET_BROWSER_LIST_SUCCESS';
export const GET_BROWSER_LIST_FAILURE = 'GET_BROWSER_LIST_FAILURE';
export const GET_COUNTRY_LIST_START = 'GET_COUNTRY_LIST_START';
export const GET_COUNTRY_LIST_SUCCESS = 'GET_COUNTRY_LIST_SUCCESS';
export const GET_COUNTRY_LIST_FAILURE = 'GET_COUNTRY_LIST_FAILURE';
export const GET_LANGUAGE_LIST_START = 'GET_LANGUAGE_LIST_START';
export const GET_LANGUAGE_LIST_SUCCESS = 'GET_LANGUAGE_LIST_SUCCESS';
export const GET_LANGUAGE_LIST_FAILURE = 'GET_LANGUAGE_LIST_FAILURE';
export const GET_LINK_TYPE_LIST_START = 'GET_LINK_TYPE_LIST_START';
export const GET_LINK_TYPE_LIST_SUCCESS = 'GET_LINK_TYPE_LIST_SUCCESS';
export const GET_LINK_TYPE_LIST_FAILURE = 'GET_LINK_TYPE_LIST_FAILURE';
