export default {
  sites: {
    list: [],
    isLoading: false,
    isSending: false,
    error: null,
    total: null,
  },
  creatives: {
    list: [],
    isLoading: false,
    isSending: false,
    error: null,
    total: null,
  },
  allWidgets: {
    list: [],
    isLoading: false,
    error: null,
  },
  allCreatives: {
    list: [],
    isLoading: false,
    error: null,
  },
  allSites: {
    list: [],
    isLoading: false,
    error: null,
  },
  allAdvertisers: {
    list: [],
    isLoading: false,
    error: null,
  },
  statistics: {
    list: [],
    isLoading: false,
    error: null,
    total: null,
  },
  advertisers: {
    list: [],
    isLoading: false,
    isSending: false,
    error: null,
    total: null,
  },
  webmasters: {
    list: [],
    isLoading: false,
    error: null,
    total: null,
  },
  userToken: {
    isSending: false,
    error: null,
  },
  userProfile: {
    info: null,
    isLoading: false,
    error: null,
  },
};
