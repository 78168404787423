import dictionaryApi from '@/api/dictionary';

import {
  GET_SITE_CATEGORIES_START,
  GET_SITE_CATEGORIES_SUCCESS,
  GET_SITE_CATEGORIES_FAILURE,
  GET_CREATIVE_TYPES_START,
  GET_CREATIVE_TYPES_SUCCESS,
  GET_CREATIVE_TYPES_FAILURE,
  GET_FORMAT_TYPES_START,
  GET_FORMAT_TYPES_SUCCESS,
  GET_FORMAT_TYPES_FAILURE,
  GET_PLATFORM_LIST_START,
  GET_PLATFORM_LIST_SUCCESS,
  GET_PLATFORM_LIST_FAILURE,
  GET_BROWSER_LIST_START,
  GET_BROWSER_LIST_SUCCESS,
  GET_BROWSER_LIST_FAILURE,
  GET_COUNTRY_LIST_START,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAILURE,
  GET_LANGUAGE_LIST_START,
  GET_LANGUAGE_LIST_SUCCESS,
  GET_LANGUAGE_LIST_FAILURE,
  GET_LINK_TYPE_LIST_START,
  GET_LINK_TYPE_LIST_SUCCESS,
  GET_LINK_TYPE_LIST_FAILURE,
} from '@/store/mutation-types/dictionary';

import {
  GET_SITE_CATEGORIES,
  GET_CREATIVE_TYPES,
  GET_FORMAT_TYPES,
  GET_PLATFORM_LIST,
  GET_BROWSER_LIST,
  GET_COUNTRY_LIST,
  GET_LANGUAGE_LIST,
  GET_LINK_TYPE_LIST,
} from '@/store/action-types/dictionary';

export default {
  [GET_SITE_CATEGORIES]({ commit }) {
    return new Promise((resolve) => {
      commit(GET_SITE_CATEGORIES_START);
      dictionaryApi
        .getSiteCategories()
        .then((siteCategories) => {
          commit(GET_SITE_CATEGORIES_SUCCESS, siteCategories.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_SITE_CATEGORIES_FAILURE);
        });
    });
  },
  [GET_CREATIVE_TYPES]({ commit }) {
    return new Promise((resolve) => {
      commit(GET_CREATIVE_TYPES_START);
      dictionaryApi
        .getCreativeTypes()
        .then((creativeTypes) => {
          commit(GET_CREATIVE_TYPES_SUCCESS, creativeTypes.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_CREATIVE_TYPES_FAILURE);
        });
    });
  },
  [GET_FORMAT_TYPES]({ commit }) {
    return new Promise((resolve) => {
      commit(GET_FORMAT_TYPES_START);
      dictionaryApi
        .getFormatTypes()
        .then((formatTypes) => {
          commit(GET_FORMAT_TYPES_SUCCESS, formatTypes.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_FORMAT_TYPES_FAILURE);
        });
    });
  },
  [GET_PLATFORM_LIST]({ commit }) {
    return new Promise((resolve) => {
      commit(GET_PLATFORM_LIST_START);
      dictionaryApi
        .getPlatformList()
        .then((platforms) => {
          commit(GET_PLATFORM_LIST_SUCCESS, platforms.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_PLATFORM_LIST_FAILURE);
        });
    });
  },
  [GET_BROWSER_LIST]({ commit }) {
    return new Promise((resolve) => {
      commit(GET_BROWSER_LIST_START);
      dictionaryApi
        .getBrowserList()
        .then((browsers) => {
          commit(GET_BROWSER_LIST_SUCCESS, browsers.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_BROWSER_LIST_FAILURE);
        });
    });
  },
  [GET_COUNTRY_LIST]({ commit }) {
    return new Promise((resolve) => {
      commit(GET_COUNTRY_LIST_START);
      dictionaryApi
        .getCountryList()
        .then((countries) => {
          commit(GET_COUNTRY_LIST_SUCCESS, countries.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_COUNTRY_LIST_FAILURE);
        });
    });
  },
  [GET_LANGUAGE_LIST]({ commit }) {
    return new Promise((resolve) => {
      commit(GET_LANGUAGE_LIST_START);
      dictionaryApi
        .getLanguageList()
        .then((languages) => {
          commit(GET_LANGUAGE_LIST_SUCCESS, languages.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_LANGUAGE_LIST_FAILURE);
        });
    });
  },
  [GET_LINK_TYPE_LIST]({ commit }) {
    return new Promise((resolve) => {
      commit(GET_LINK_TYPE_LIST_START);
      dictionaryApi
        .getLinkTypeList()
        .then((linkTypes) => {
          commit(GET_LINK_TYPE_LIST_SUCCESS, linkTypes.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_LINK_TYPE_LIST_FAILURE);
        });
    });
  },
};
