export default {
  siteCategories: {
    list: [],
    isLoading: false,
    error: null,
  },
  creativeTypes: {
    list: [],
    isLoading: false,
    error: null,
  },
  formatTypes: {
    list: [],
    isLoading: false,
    error: null,
  },
  platforms: {
    list: [],
    isLoading: false,
    error: null,
  },
  browsers: {
    list: [],
    isLoading: false,
    error: null,
  },
  countries: {
    list: [],
    isLoading: false,
    error: null,
  },
  languages: {
    list: [],
    isLoading: false,
    error: null,
  },
  linkTypes: {
    list: [],
    isLoading: false,
    error: null,
  },
};
