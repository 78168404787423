import {
  TOGGLE_SIDEBAR_DESKTOP,
  TOGGLE_SIDEBAR_MOBILE,
  SET,
  TOGGLE,
  SET_INTERFACE_LANGUAGE,
} from '@/store/mutation-types/ui';

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  darkMode: false,
  interfaceLanguage: 'en',
};

const mutations = {
  [TOGGLE_SIDEBAR_DESKTOP](state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : 'responsive';
  },
  [TOGGLE_SIDEBAR_MOBILE](state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : 'responsive';
  },
  [SET](state, [variable, value]) {
    state[variable] = value;
  },
  [TOGGLE](state, variable) {
    state[variable] = !state[variable];
  },
  [SET_INTERFACE_LANGUAGE](state, payload) {
    state.interfaceLanguage = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
