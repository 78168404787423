import { format } from 'date-fns';
import creativesApi from '@/api/creatives';
import { getMatches, getMatchById } from '@/api/matches';

import {
  GET_CREATIVES_LIST_START,
  GET_CREATIVES_LIST_SUCCESS,
  GET_CREATIVES_LIST_FAILURE,
  GET_CAMPAIGN_CREATIVES_LIST_START,
  GET_CAMPAIGN_CREATIVES_LIST_SUCCESS,
  GET_CAMPAIGN_CREATIVES_LIST_FAILURE,
  CREATE_CREATIVE_START,
  CREATE_CREATIVE_SUCCESS,
  CREATE_CREATIVE_FAILURE,
  GET_CREATIVE_START,
  GET_CREATIVE_SUCCESS,
  GET_CREATIVE_FAILURE,
  EDIT_CREATIVE_START,
  EDIT_CREATIVE_SUCCESS,
  EDIT_CREATIVE_FAILURE,
  UPLOAD_MEDIA_START,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_FAILURE,
  EDIT_CREATIVE_MEDIA_START,
  EDIT_CREATIVE_MEDIA_SUCCESS,
  EDIT_CREATIVE_MEDIA_FAILURE,
  GET_MATCHES_LIST_START,
  GET_MATCHES_LIST_SUCCESS,
  GET_MATCHES_LIST_FAILURE,
  GET_MATCH_BY_ID_START,
  GET_MATCH_BY_ID_SUCCESS,
  GET_MATCH_BY_ID_FAILURE,
} from '@/store/mutation-types/creatives';

import {
  GET_CREATIVES_LIST,
  GET_CAMPAIGN_CREATIVES_LIST,
  CREATE_CREATIVE,
  GET_CREATIVE,
  EDIT_CREATIVE,
  UPLOAD_MEDIA,
  EDIT_CREATIVE_MEDIA,
  GET_MATCHES_LIST,
  GET_MATCH_BY_ID,
} from '@/store/action-types/creatives';

const state = {
  creativesList: [],
  creative: null,
  total: null,
  isLoading: false,
  error: null,
  isSending: false,
  matchList: [],
  selectedMatch: null,
  matchListLoading: false,
};

const mutations = {
  [GET_CREATIVES_LIST_START](state) {
    state.isLoading = true;
    state.creativesList = [];
    state.total = null;
    state.error = false;
  },
  [GET_CREATIVES_LIST_SUCCESS](state, payload) {
    state.isLoading = false;
    state.creativesList = payload.items;
    state.total = payload.total;
  },
  [GET_CREATIVES_LIST_FAILURE](state) {
    state.isLoading = false;
    state.error = true;
  },
  [GET_CAMPAIGN_CREATIVES_LIST_START](state) {
    state.isLoading = true;
    state.creativesList = [];
    state.total = null;
    state.error = false;
  },
  [GET_CAMPAIGN_CREATIVES_LIST_SUCCESS](state, payload) {
    state.isLoading = false;
    state.creativesList = payload.items;
    state.total = payload.total;
  },
  [GET_CAMPAIGN_CREATIVES_LIST_FAILURE](state) {
    state.isLoading = false;
    state.error = true;
  },
  [CREATE_CREATIVE_START](state) {
    state.isSending = true;
    state.error = null;
  },
  [CREATE_CREATIVE_SUCCESS](state) {
    state.isSending = false;
  },
  [CREATE_CREATIVE_FAILURE](state) {
    state.isSending = false;
  },
  [GET_CREATIVE_START](state) {
    state.isLoading = true;
    state.creative = null;
    state.error = null;
  },
  [GET_CREATIVE_SUCCESS](state, payload) {
    state.isLoading = false;
    state.creative = payload;
  },
  [GET_CREATIVE_FAILURE](state) {
    state.isLoading = false;
    state.error = true;
  },
  [EDIT_CREATIVE_START](state) {
    state.isSending = true;
    state.error = null;
  },
  [EDIT_CREATIVE_SUCCESS](state) {
    state.isSending = false;
  },
  [EDIT_CREATIVE_FAILURE](state) {
    state.isSending = false;
  },
  [UPLOAD_MEDIA_START](state) {
    state.isSending = true;
    state.error = null;
  },
  [UPLOAD_MEDIA_SUCCESS](state) {
    state.isSending = false;
  },
  [UPLOAD_MEDIA_FAILURE](state) {
    state.isSending = false;
  },
  [EDIT_CREATIVE_MEDIA_START](state) {
    state.isSending = true;
    state.error = null;
  },
  [EDIT_CREATIVE_MEDIA_SUCCESS](state) {
    state.isSending = false;
  },
  [EDIT_CREATIVE_MEDIA_FAILURE](state) {
    state.isSending = false;
  },
  [GET_MATCHES_LIST_START](state) {
    state.matchListLoading = true;
  },
  [GET_MATCHES_LIST_SUCCESS](state, payload) {
    state.matchList = payload;
    state.matchListLoading = false;
  },
  [GET_MATCH_BY_ID_SUCCESS](state, payload) {
    state.selectedMatch = payload;
  },
};

const actions = {
  [GET_MATCH_BY_ID]({ commit }, payload) {
    return new Promise((resolve) => {
      commit(GET_MATCHES_LIST_START);
      getMatchById(payload)
        .then((response) => {
          commit(GET_MATCH_BY_ID_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          // commit(GET_MATCHES_LIST_FAILURE);
        });
    });
  },
  [GET_MATCHES_LIST]({ commit }) {
    return new Promise((resolve) => {
      commit(GET_MATCHES_LIST_START);
      getMatches()
        .then((response) => {
          commit(GET_MATCHES_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_MATCHES_LIST_FAILURE);
        });
    });
  },
  [GET_CREATIVES_LIST]({ commit }, payload) {
    return new Promise((resolve) => {
      commit(GET_CREATIVES_LIST_START);
      creativesApi
        .getCreativesList(payload)
        .then((response) => {
          commit(GET_CREATIVES_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_CREATIVES_LIST_FAILURE);
        });
    });
  },
  [GET_CAMPAIGN_CREATIVES_LIST]({ commit }, { id, params }) {
    return new Promise((resolve) => {
      commit(GET_CAMPAIGN_CREATIVES_LIST_START);
      creativesApi
        .getCampaignCreativesList(id, params)
        .then((response) => {
          commit(GET_CAMPAIGN_CREATIVES_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_CAMPAIGN_CREATIVES_LIST_FAILURE);
        });
    });
  },
  [CREATE_CREATIVE]({ commit }, { id, formData }) {
    return new Promise((resolve, reject) => {
      commit(CREATE_CREATIVE_START);
      creativesApi
        .createCreative(id, formData)
        .then(() => {
          commit(CREATE_CREATIVE_SUCCESS);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(CREATE_CREATIVE_FAILURE);
          reject();
        });
    });
  },
  [GET_CREATIVE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(GET_CREATIVE_START);
      creativesApi
        .getCreative(payload)
        .then((creative) => {
          commit(GET_CREATIVE_SUCCESS, creative.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_CREATIVE_FAILURE);
          reject();
        });
    });
  },
  [EDIT_CREATIVE]({ commit }, { id, formData }) {
    return new Promise((resolve, reject) => {
      commit(EDIT_CREATIVE_START);
      creativesApi
        .editCreative(id, formData)
        .then(() => {
          commit(EDIT_CREATIVE_SUCCESS);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(EDIT_CREATIVE_FAILURE);
          reject();
        });
    });
  },
  [UPLOAD_MEDIA]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(UPLOAD_MEDIA_START);
      creativesApi
        .uploadMedia(payload.id, payload.formData)
        .then(() => {
          commit(UPLOAD_MEDIA_SUCCESS);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(UPLOAD_MEDIA_FAILURE);
          reject();
        });
    });
  },
  [EDIT_CREATIVE_MEDIA]({ commit }, { id, model }) {
    return new Promise((resolve, reject) => {
      commit(EDIT_CREATIVE_MEDIA_START);
      creativesApi
        .editCreativeMedia(id, model)
        .then(() => {
          commit(EDIT_CREATIVE_MEDIA_SUCCESS);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(EDIT_CREATIVE_MEDIA_FAILURE);
          reject();
        });
    });
  },
};

const getters = {
  creativesList(state) {
    return state.creativesList
      .map((creative) => {
        return {
          label: creative.name,
          value: creative.id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  },
  matchesList(state) {
    const matchesList = [];
    state.matchList.map(({ beginAt, championshipTitle, matchTitle, matchId, inTop }) => {
      matchesList.push({
        label: `${format(new Date(beginAt * 1000), 'dd.MM HH:mm')} ${championshipTitle} ${matchTitle}`,
        value: matchId,
        disabled: !inTop,
      });
    });
    if (state.selectedMatch) {
      if (!state.matchList.find(({ matchId }) => state.selectedMatch.matchId === matchId)) {
        matchesList.push({
          label: `${format(new Date(state.selectedMatch.beginAt * 1000), 'dd.MM HH:mm')} ${
            state.selectedMatch.championshipTitle
          } ${state.selectedMatch.matchTitle}`,
          value: state.selectedMatch.matchId,
          disabled: !state.selectedMatch.inTop,
        });
      }
    }
    return matchesList;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
