<template>
  <div>
    <router-view></router-view>
    <notifications group="main" :duration="5000" />

    <CModal :title="$t('modal.attention')" :centered="true" color="warning" :show.sync="warningModal">
      <p>{{ $t('modal.adblock_enabled') }}</p>
      <template #footer>
        <CButton size="sm" class="mr-3" color="warning" @click="warningModal = false">{{ $t('modal.close') }}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import adBlockCheck from '@/helpers/adblock-check';
import { mapGetters, mapMutations } from 'vuex';
import { getItem } from '@/helpers/persistance-storage';
import { TOGGLE, SET_INTERFACE_LANGUAGE } from '@/store/mutation-types/ui';

export default {
  name: 'App',
  data() {
    return {
      isAdblockEnabled: false,
      warningModal: false,
    };
  },
  computed: {
    ...mapGetters('roles', ['isAdvertiser']),
  },
  watch: {
    isAdvertiser(val) {
      if (val) {
        this.adBlockCheck();
      }
    },
  },
  mounted() {
    this.checkTokenExpire();
    this.checkLanguageSettings();
    this.checkThemeSettings();
  },
  methods: {
    ...mapMutations('ui', [SET_INTERFACE_LANGUAGE, TOGGLE]),
    checkTokenExpire() {
      const accessToken = getItem('accessToken');

      if (accessToken) {
        const date = Date.now();
        const isTokenExpired = accessToken?.expires !== 0 && accessToken?.expires < date;

        if (isTokenExpired) {
          localStorage.removeItem('accessToken');
        }
      }
    },
    checkLanguageSettings() {
      const interfaceLanguage = localStorage.getItem('interfaceLanguage');

      if (interfaceLanguage) {
        this.$i18n.locale = interfaceLanguage;
        this.SET_INTERFACE_LANGUAGE(interfaceLanguage);
      }
    },
    checkThemeSettings() {
      const theme = localStorage.getItem('theme-mode');

      if (theme) {
        this.TOGGLE(theme);
      }
    },
    adBlockCheck() {
      adBlockCheck().then((result) => {
        this.isAdblockEnabled = result;
        this.warningModal = result;
      });
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/style';
</style>
