export const GET_CREATIVES_LIST_START = 'GET_CREATIVES_LIST_START';
export const GET_CREATIVES_LIST_SUCCESS = 'GET_CREATIVES_LIST_SUCCESS';
export const GET_CREATIVES_LIST_FAILURE = 'GET_CREATIVES_LIST_FAILURE';
export const GET_CAMPAIGN_CREATIVES_LIST_START = 'GET_CAMPAIGN_CREATIVES_LIST_START';
export const GET_CAMPAIGN_CREATIVES_LIST_SUCCESS = 'GET_CAMPAIGN_CREATIVES_LIST_SUCCESS';
export const GET_CAMPAIGN_CREATIVES_LIST_FAILURE = 'GET_CAMPAIGN_CREATIVES_LIST_FAILURE';
export const CREATE_CREATIVE_START = 'CREATE_CREATIVE_START';
export const CREATE_CREATIVE_SUCCESS = 'CREATE_CREATIVE_SUCCESS';
export const CREATE_CREATIVE_FAILURE = 'CREATE_CREATIVE_FAILURE';
export const GET_CREATIVE_START = 'GET_CREATIVE_START';
export const GET_CREATIVE_SUCCESS = 'GET_CREATIVE_SUCCESS';
export const GET_CREATIVE_FAILURE = 'GET_CREATIVE_FAILURE';
export const EDIT_CREATIVE_START = 'EDIT_CREATIVE_START';
export const EDIT_CREATIVE_SUCCESS = 'EDIT_CREATIVE_SUCCESS';
export const EDIT_CREATIVE_FAILURE = 'EDIT_CREATIVE_FAILURE';
export const UPLOAD_MEDIA_START = 'UPLOAD_MEDIA_START';
export const UPLOAD_MEDIA_SUCCESS = 'UPLOAD_MEDIA_SUCCESS';
export const UPLOAD_MEDIA_FAILURE = 'UPLOAD_MEDIA_FAILURE';
export const EDIT_CREATIVE_MEDIA_START = 'EDIT_CREATIVE_MEDIA_START';
export const EDIT_CREATIVE_MEDIA_SUCCESS = 'EDIT_CREATIVE_MEDIA_SUCCESS';
export const EDIT_CREATIVE_MEDIA_FAILURE = 'EDIT_CREATIVE_MEDIA_FAILURE';
export const GET_MATCHES_LIST_START = 'GET_MATCHES_LIST_START';
export const GET_MATCHES_LIST_SUCCESS = 'GET_MATCHES_LIST_SUCCESS';
export const GET_MATCHES_LIST_FAILURE = 'GET_MATCHES_LIST_FAILURE';
export const GET_MATCH_BY_ID_START = 'GET_MATCH_BY_ID_START';
export const GET_MATCH_BY_ID_SUCCESS = 'GET_MATCH_BY_ID_SUCCESS';
export const GET_MATCH_BY_ID_FAILURE = 'GET_MATCH_BY_ID_FAILURE';
