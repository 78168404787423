import {
  GET_SITE_CATEGORIES_START,
  GET_SITE_CATEGORIES_SUCCESS,
  GET_SITE_CATEGORIES_FAILURE,
  GET_CREATIVE_TYPES_START,
  GET_CREATIVE_TYPES_SUCCESS,
  GET_CREATIVE_TYPES_FAILURE,
  GET_FORMAT_TYPES_START,
  GET_FORMAT_TYPES_SUCCESS,
  GET_FORMAT_TYPES_FAILURE,
  GET_PLATFORM_LIST_START,
  GET_PLATFORM_LIST_SUCCESS,
  GET_PLATFORM_LIST_FAILURE,
  GET_BROWSER_LIST_START,
  GET_BROWSER_LIST_SUCCESS,
  GET_BROWSER_LIST_FAILURE,
  GET_COUNTRY_LIST_START,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAILURE,
  GET_LANGUAGE_LIST_START,
  GET_LANGUAGE_LIST_SUCCESS,
  GET_LANGUAGE_LIST_FAILURE,
  GET_LINK_TYPE_LIST_START,
  GET_LINK_TYPE_LIST_SUCCESS,
  GET_LINK_TYPE_LIST_FAILURE,
} from '@/store/mutation-types/dictionary';

export default {
  [GET_SITE_CATEGORIES_START](state) {
    state.siteCategories.isLoading = true;
    state.siteCategories.error = false;
    state.siteCategories.list = [];
  },
  [GET_SITE_CATEGORIES_SUCCESS](state, payload) {
    state.siteCategories.isLoading = false;
    state.siteCategories.list = payload;
  },
  [GET_SITE_CATEGORIES_FAILURE](state) {
    state.siteCategories.isLoading = false;
    state.siteCategories.error = true;
  },
  [GET_CREATIVE_TYPES_START](state) {
    state.creativeTypes.isLoading = true;
    state.creativeTypes.error = false;
    state.creativeTypes.list = [];
  },
  [GET_CREATIVE_TYPES_SUCCESS](state, payload) {
    state.creativeTypes.isLoading = false;
    state.creativeTypes.list = payload;
  },
  [GET_CREATIVE_TYPES_FAILURE](state) {
    state.creativeTypes.isLoading = false;
    state.creativeTypes.error = true;
  },
  [GET_FORMAT_TYPES_START](state) {
    state.formatTypes.isLoading = true;
    state.formatTypes.error = false;
    state.formatTypes.list = [];
  },
  [GET_FORMAT_TYPES_SUCCESS](state, payload) {
    state.formatTypes.isLoading = false;
    state.formatTypes.list = payload;
  },
  [GET_FORMAT_TYPES_FAILURE](state) {
    state.formatTypes.isLoading = false;
    state.formatTypes.error = true;
  },
  [GET_PLATFORM_LIST_START](state) {
    state.platforms.isLoading = true;
    state.platforms.error = false;
    state.platforms.list = [];
  },
  [GET_PLATFORM_LIST_SUCCESS](state, payload) {
    state.platforms.isLoading = false;
    state.platforms.list = payload;
  },
  [GET_PLATFORM_LIST_FAILURE](state) {
    state.platforms.isLoading = false;
    state.platforms.error = true;
  },
  [GET_BROWSER_LIST_START](state) {
    state.browsers.isLoading = true;
    state.browsers.error = false;
    state.browsers.list = [];
  },
  [GET_BROWSER_LIST_SUCCESS](state, payload) {
    state.browsers.isLoading = false;
    state.browsers.list = payload;
  },
  [GET_BROWSER_LIST_FAILURE](state) {
    state.browsers.isLoading = false;
    state.browsers.error = true;
  },
  [GET_COUNTRY_LIST_START](state) {
    state.countries.isLoading = true;
    state.countries.error = false;
    state.countries.list = [];
  },
  [GET_COUNTRY_LIST_SUCCESS](state, payload) {
    state.countries.isLoading = false;
    state.countries.list = payload;
  },
  [GET_COUNTRY_LIST_FAILURE](state) {
    state.countries.isLoading = false;
    state.countries.error = true;
  },
  [GET_LANGUAGE_LIST_START](state) {
    state.languages.isLoading = true;
    state.languages.error = false;
    state.languages.list = [];
  },
  [GET_LANGUAGE_LIST_SUCCESS](state, payload) {
    state.languages.isLoading = false;
    state.languages.list = payload;
  },
  [GET_LANGUAGE_LIST_FAILURE](state) {
    state.languages.isLoading = false;
    state.languages.error = true;
  },
  [GET_LINK_TYPE_LIST_START](state) {
    state.linkTypes.isLoading = true;
    state.linkTypes.error = false;
    state.linkTypes.list = [];
  },
  [GET_LINK_TYPE_LIST_SUCCESS](state, payload) {
    state.linkTypes.isLoading = false;
    state.linkTypes.list = payload;
  },
  [GET_LINK_TYPE_LIST_FAILURE](state) {
    state.linkTypes.isLoading = false;
    state.linkTypes.error = true;
  },
};
