import authApi from '@/api/auth';
import { setItem } from '@/helpers/persistance-storage';

import { REGISTER, LOGIN } from '@/store/action-types/auth';

import {
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CLEAR_ERRORS,
  LOGOUT,
} from '@/store/mutation-types/auth';

const state = {
  isLoggedIn: false,
  isSubmitting: false,
  isLoading: false,
  errors: null,
};

const mutations = {
  [REGISTER_START](state) {
    state.isSubmitting = true;
    state.errors = null;
  },
  [REGISTER_SUCCESS](state) {
    state.isSubmitting = false;
    state.isLoggedIn = true;
  },
  [REGISTER_FAILURE](state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },
  [LOGIN_START](state) {
    state.isSubmitting = true;
    state.errors = null;
  },
  [LOGIN_SUCCESS](state) {
    state.isSubmitting = false;
    state.isLoggedIn = true;
  },
  [LOGIN_FAILURE](state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },
  [LOGOUT](state) {
    state.isLoggedIn = false;
  },
  [CLEAR_ERRORS](state) {
    state.errors = null;
  },
};

const actions = {
  [REGISTER]({ commit }, credentials) {
    return new Promise((resolve) => {
      commit(REGISTER_START);
      authApi
        .register(credentials)
        .then((response) => {
          commit(REGISTER_SUCCESS);
          setItem('accessToken', {
            expires: response.data.expires,
            token: response.data.token,
          });
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(REGISTER_FAILURE, error.response.status);
        });
    });
  },
  [LOGIN]({ commit }, credentials) {
    return new Promise((resolve) => {
      commit(LOGIN_START);
      authApi
        .login(credentials)
        .then((response) => {
          commit(LOGIN_SUCCESS);
          setItem('accessToken', {
            expires: response.data.expires,
            token: response.data.token,
          });
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(LOGIN_FAILURE, error.response.status);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
