export default {
  campaignList: [],
  campaign: null,
  total: null,
  isLoading: false,
  error: null,
  isSending: false,
  domainList: [],
  webmastersList: [],
};
