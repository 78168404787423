export const GET_SITES_LIST_START = 'GET_SITES_LIST_START';
export const GET_SITES_LIST_SUCCESS = 'GET_SITES_LIST_SUCCESS';
export const GET_SITES_LIST_FAILURE = 'GET_SITES_LIST_FAILURE';
export const EDIT_SITE_START = 'EDIT_SITE_START';
export const EDIT_SITE_SUCCESS = 'EDIT_SITE_SUCCESS';
export const EDIT_SITE_FAILURE = 'EDIT_SITE_FAILURE';
export const GET_CREATIVES_LIST_START = 'GET_CREATIVES_LIST_START';
export const GET_CREATIVES_LIST_SUCCESS = 'GET_CREATIVES_LIST_SUCCESS';
export const GET_CREATIVES_LIST_FAILURE = 'GET_CREATIVES_LIST_FAILURE';
export const EDIT_CREATIVE_START = 'EDIT_CREATIVE_START';
export const EDIT_CREATIVE_SUCCESS = 'EDIT_CREATIVE_SUCCESS';
export const EDIT_CREATIVE_FAILURE = 'EDIT_CREATIVE_FAILURE';
export const GET_FULL_WIDGETS_LIST_START = 'GET_FULL_WIDGETS_LIST_START';
export const GET_FULL_WIDGETS_LIST_SUCCESS = 'GET_FULL_WIDGETS_LIST_SUCCESS';
export const GET_FULL_WIDGETS_LIST_FAILURE = 'GET_FULL_WIDGETS_LIST_FAILURE';
export const GET_FULL_CREATIVES_LIST_START = 'GET_FULL_CREATIVES_LIST_START';
export const GET_FULL_CREATIVES_LIST_SUCCESS = 'GET_FULL_CREATIVES_LIST_SUCCESS';
export const GET_FULL_CREATIVES_LIST_FAILURE = 'GET_FULL_CREATIVES_LIST_FAILURE';
export const GET_FULL_SITES_LIST_START = 'GET_FULL_SITES_LIST_START';
export const GET_FULL_SITES_LIST_SUCCESS = 'GET_FULL_SITES_LIST_SUCCESS';
export const GET_FULL_SITES_LIST_FAILURE = 'GET_FULL_SITES_LIST_FAILURE';
export const GET_FULL_ADVERTISERS_LIST_START = 'GET_FULL_ADVERTISERS_LIST_START';
export const GET_FULL_ADVERTISERS_LIST_SUCCESS = 'GET_FULL_ADVERTISERS_LIST_SUCCESS';
export const GET_FULL_ADVERTISERS_LIST_FAILURE = 'GET_FULL_ADVERTISERS_LIST_FAILURE';
export const GET_STATISTICS_START = 'GET_STATISTICS_START';
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS';
export const GET_STATISTICS_FAILURE = 'GET_STATISTICS_FAILURE';
export const GET_ADVERTISERS_LIST_START = 'GET_ADVERTISERS_LIST_START';
export const GET_ADVERTISERS_LIST_SUCCESS = 'GET_ADVERTISERS_LIST_SUCCESS';
export const GET_ADVERTISERS_LIST_FAILURE = 'GET_ADVERTISERS_LIST_FAILURE';
export const GET_WEBMASTERS_LIST_START = 'GET_WEBMASTERS_LIST_START';
export const GET_WEBMASTERS_LIST_SUCCESS = 'GET_WEBMASTERS_LIST_SUCCESS';
export const GET_WEBMASTERS_LIST_FAILURE = 'GET_WEBMASTERS_LIST_FAILURE';
export const GET_USER_TOKEN_START = 'GET_USER_TOKEN_START';
export const GET_USER_TOKEN_SUCCESS = 'GET_USER_TOKEN_SUCCESS';
export const GET_USER_TOKEN_FAILURE = 'GET_USER_TOKEN_FAILURE';
export const GET_SITE_START = 'GET_SITE_START';
export const GET_SITE_SUCCESS = 'GET_SITE_SUCCESS';
export const GET_SITE_FAILURE = 'GET_SITE_FAILURE';
export const EDIT_ADVERTISER_PROFILE_START = 'EDIT_ADVERTISER_PROFILE_START';
export const EDIT_ADVERTISER_PROFILE_SUCCESS = 'EDIT_ADVERTISER_PROFILE_SUCCESS';
export const EDIT_ADVERTISER_PROFILE_FAILURE = 'EDIT_ADVERTISER_PROFILE_FAILURE';
export const GET_USER_PROFILE_START = 'GET_USER_PROFILE_START';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';
