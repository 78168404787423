import {
  GET_CAMPAIGN_LIST_START,
  GET_CAMPAIGN_LIST_SUCCESS,
  GET_CAMPAIGN_LIST_FAILURE,
  CREATE_CAMPAIGN_START,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,
  CLONE_CAMPAIGN_START,
  CLONE_CAMPAIGN_SUCCESS,
  CLONE_CAMPAIGN_FAILURE,
  CREATE_CAMPAIGN_CREATIVE_START,
  CREATE_CAMPAIGN_CREATIVE_SUCCESS,
  CREATE_CAMPAIGN_CREATIVE_FAILURE,
  GET_CAMPAIGN_START,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_FAILURE,
  EDIT_CAMPAIGN_START,
  EDIT_CAMPAIGN_SUCCESS,
  EDIT_CAMPAIGN_FAILURE,
  GET_DOMAIN_LIST_START,
  GET_DOMAIN_LIST_SUCCESS,
  GET_DOMAIN_LIST_FAILURE,
  GET_WEBMASTERS_LIST_START,
  GET_WEBMASTERS_LIST_SUCCESS,
  GET_WEBMASTERS_LIST_FAILURE,
} from '@/store/mutation-types/campaigns';

export default {
  [GET_CAMPAIGN_LIST_START](state) {
    state.isLoading = true;
    state.campaignList = [];
    state.total = 0;
    state.error = false;
  },
  [GET_CAMPAIGN_LIST_SUCCESS](state, payload) {
    state.isLoading = false;
    state.campaignList = payload.items;
    state.total = payload.total;
  },
  [GET_CAMPAIGN_LIST_FAILURE](state) {
    state.isLoading = false;
    state.error = true;
  },
  [CREATE_CAMPAIGN_START](state) {
    state.isSending = true;
    state.error = null;
  },
  [CREATE_CAMPAIGN_SUCCESS](state) {
    state.isSending = false;
  },
  [CREATE_CAMPAIGN_FAILURE](state) {
    state.isSending = false;
  },
  [GET_CAMPAIGN_START](state) {
    state.isLoading = true;
    state.campaign = null;
    state.error = null;
  },
  [GET_CAMPAIGN_SUCCESS](state, payload) {
    state.isLoading = false;
    state.campaign = payload;
  },
  [GET_CAMPAIGN_FAILURE](state) {
    state.isLoading = false;
    state.error = true;
  },
  [EDIT_CAMPAIGN_START](state) {
    state.isSending = true;
    state.error = null;
  },
  [EDIT_CAMPAIGN_SUCCESS](state) {
    state.isSending = false;
  },
  [EDIT_CAMPAIGN_FAILURE](state) {
    state.isSending = false;
  },
  [CLONE_CAMPAIGN_START](state) {
    state.isSending = true;
    state.error = null;
  },
  [CLONE_CAMPAIGN_SUCCESS](state) {
    state.isSending = false;
  },
  [CLONE_CAMPAIGN_FAILURE](state) {
    state.isSending = true;
  },
  [CREATE_CAMPAIGN_CREATIVE_START](state) {
    state.isSending = true;
    state.error = null;
  },
  [CREATE_CAMPAIGN_CREATIVE_SUCCESS](state) {
    state.isSending = false;
  },
  [CREATE_CAMPAIGN_CREATIVE_FAILURE](state) {
    state.isSending = false;
  },
  [GET_DOMAIN_LIST_START](state) {
    state.domainList = [];
  },
  [GET_DOMAIN_LIST_SUCCESS](state, payload) {
    state.domainList = payload;
  },
  [GET_DOMAIN_LIST_FAILURE]() { },
  [GET_WEBMASTERS_LIST_START](state) {
    state.webmastersList = [];
  },
  [GET_WEBMASTERS_LIST_SUCCESS](state, payload) {
    state.webmastersList = payload;
  },
  [GET_WEBMASTERS_LIST_FAILURE]() { },
};
