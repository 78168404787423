import axios from '@/api/axios';

const getSiteCategories = () => {
  return axios.get('/dictionary/site_category');
};

const getCreativeTypes = () => {
  return axios.get('/dictionary/creative_type');
};
const getFormatTypes = () => {
  return axios.get('/dictionary/format');
};

const getPlatformList = () => {
  return axios.get('/dictionary/platform');
};

const getBrowserList = () => {
  return axios.get('/dictionary/browser');
};

const getCountryList = () => {
  return axios.get('/dictionary/country');
};

const getLanguageList = () => {
  return axios.get('/dictionary/language');
};

const getLinkTypeList = () => {
  return axios.get('/dictionary/link_type');
};

export default {
  getSiteCategories,
  getCreativeTypes,
  getFormatTypes,
  getPlatformList,
  getBrowserList,
  getCountryList,
  getLanguageList,
  getLinkTypeList,
};
