import sitesApi from '@/api/sites';
import { getUniqueListBy } from '@/helpers/utils';

import {
  GET_SITES_LIST_START,
  GET_SITES_LIST_SUCCESS,
  GET_SITES_LIST_FAILURE,
  CREATE_SITE_START,
  CREATE_SITE_SUCCESS,
  CREATE_SITE_FAILURE,
  GET_SITE_START,
  GET_SITE_SUCCESS,
  GET_SITE_FAILURE,
  EDIT_SITE_START,
  EDIT_SITE_SUCCESS,
  EDIT_SITE_FAILURE,
} from '@/store/mutation-types/sites';

import { GET_SITES_LIST, CREATE_SITE, GET_SITE, EDIT_SITE } from '@/store/action-types/sites';

const state = {
  sitesList: [],
  site: null,
  total: null,
  isLoading: false,
  error: null,
  isSending: false,
};

const mutations = {
  [GET_SITES_LIST_START](state) {
    state.isLoading = true;
    state.sitesList = [];
    state.total = null;
    state.error = false;
  },
  [GET_SITES_LIST_SUCCESS](state, payload) {
    state.isLoading = false;
    state.sitesList = payload.items;
    state.total = payload.total;
  },
  [GET_SITES_LIST_FAILURE](state) {
    state.isLoading = false;
    state.error = true;
  },
  [CREATE_SITE_START](state) {
    state.isSending = true;
    state.error = null;
  },
  [CREATE_SITE_SUCCESS](state) {
    state.isSending = false;
  },
  [CREATE_SITE_FAILURE](state) {
    state.isSending = false;
  },
  [GET_SITE_START](state) {
    state.isLoading = true;
    state.site = null;
    state.error = false;
  },
  [GET_SITE_SUCCESS](state, payload) {
    state.isLoading = false;
    state.site = payload;
  },
  [GET_SITE_FAILURE](state) {
    state.isLoading = false;
    state.error = true;
  },
  [EDIT_SITE_START](state) {
    state.isSending = true;
    state.error = null;
  },
  [EDIT_SITE_SUCCESS](state) {
    state.isSending = false;
  },
  [EDIT_SITE_FAILURE](state) {
    state.isSending = false;
  },
};

const actions = {
  [GET_SITES_LIST]({ commit }, payload) {
    return new Promise((resolve) => {
      commit(GET_SITES_LIST_START);
      sitesApi
        .getSitesList(payload)
        .then((response) => {
          commit(GET_SITES_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_SITES_LIST_FAILURE);
        });
    });
  },
  [CREATE_SITE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(CREATE_SITE_START);
      sitesApi
        .createSite(payload)
        .then(() => {
          commit(CREATE_SITE_SUCCESS);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(CREATE_SITE_FAILURE);
          reject();
        });
    });
  },
  [GET_SITE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(GET_SITE_START);
      sitesApi
        .getSite(payload)
        .then((site) => {
          commit(GET_SITE_SUCCESS, site.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_SITE_FAILURE);
          reject();
        });
    });
  },
  [EDIT_SITE]({ commit }, { id, formData }) {
    return new Promise((resolve, reject) => {
      commit(EDIT_SITE_START);
      sitesApi
        .editSite(id, formData)
        .then(() => {
          commit(EDIT_SITE_SUCCESS);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(EDIT_SITE_FAILURE);
          reject();
        });
    });
  },
};

const getters = {
  siteList(state) {
    return state.sitesList
      .map((site) => {
        return {
          label: site.url,
          value: site.id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
