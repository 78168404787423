import 'core-js/stable';
import Vue from 'vue';
import CoreuiVue from '@coreui/vue/src';
import App from './App';
import router from './router/index';
import { iconsSet as icons } from './assets/icons/icons.js';
import store from './store';
import i18n from './i18n';
import Notifications from 'vue-notification';
import Vuelidate from 'vuelidate';
import Multiselect from 'vue-multiselect';
import { translateConsts } from './helpers/const.js';

Vue.use(CoreuiVue);
Vue.use(Notifications);
Vue.use(Vuelidate);

Vue.component('Multiselect', Multiselect);

Vue.config.productionTip = process.env.VUE_APP_ENVIRONMENT === 'development';
Vue.config.devtools = process.env.VUE_APP_ENVIRONMENT === 'development';

const vueObject = new Vue({
  el: '#app',
  i18n,
  router,
  store,
  icons,
  components: {
    App,
  },
  template: '<App/>',
});

translateConsts(vueObject);
