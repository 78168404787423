const adBlockCheck = () => {
  return new Promise((resolve) => {
    let adBlockEnabled = false;

    if (window.canRunAds === undefined) {
      adBlockEnabled = true;
    }

    resolve(adBlockEnabled);
  });
};

export default adBlockCheck;
