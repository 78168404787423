import axios from '@/api/axios';

const register = (credentials) => {
  return axios.post('/auth/signup', credentials);
};

const login = (credentials) => {
  return axios.post('/auth/login', credentials);
};

export default { register, login };
