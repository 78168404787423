const copyToClipboard = (text) => {
  const el = document.createElement('input');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const getBadge = (status) => {
  switch (status) {
    case true:
      return 'success';
    case false:
      return 'danger';
    default:
      return 'success';
  }
};

export { copyToClipboard, getBadge };
