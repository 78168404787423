import statisticsApi from '@/api/statistics';

import {
  GET_WEBMASTER_STATISTICS_START,
  GET_WEBMASTER_STATISTICS_SUCCESS,
  GET_WEBMASTER_STATISTICS_FAILURE,
  GET_ADVERTISER_STATISTICS_START,
  GET_ADVERTISER_STATISTICS_SUCCESS,
  GET_ADVERTISER_STATISTICS_FAILURE,
} from '@/store/mutation-types/statistics';

import { GET_WEBMASTER_STATISTICS, GET_ADVERTISER_STATISTICS } from '@/store/action-types/statistics';

const state = {
  statisticsWebmaster: {
    statistics: [],
    isLoading: false,
    error: null,
    total: null,
  },
  statisticsAdvertiser: {
    statistics: [],
    isLoading: false,
    error: null,
    total: null,
  },
};

const mutations = {
  [GET_WEBMASTER_STATISTICS_START](state) {
    state.statisticsWebmaster.isLoading = true;
    state.statisticsWebmaster.statistics = [];
    state.statisticsWebmaster.total = null;
    state.statisticsWebmaster.error = null;
  },
  [GET_WEBMASTER_STATISTICS_SUCCESS](state, payload) {
    state.statisticsWebmaster.isLoading = false;
    state.statisticsWebmaster.statistics = payload.items;
    state.statisticsWebmaster.total = payload.total;
  },
  [GET_WEBMASTER_STATISTICS_FAILURE](state) {
    state.statisticsWebmaster.isLoading = false;
    state.statisticsWebmaster.error = true;
  },
  [GET_ADVERTISER_STATISTICS_START](state) {
    state.statisticsAdvertiser.isLoading = true;
    state.statisticsAdvertiser.statistics = [];
    state.statisticsAdvertiser.total = null;
    state.statisticsAdvertiser.error = null;
  },
  [GET_ADVERTISER_STATISTICS_SUCCESS](state, payload) {
    state.statisticsAdvertiser.isLoading = false;
    state.statisticsAdvertiser.statistics = payload.items;
    state.statisticsAdvertiser.total = payload.total;
  },
  [GET_ADVERTISER_STATISTICS_FAILURE](state) {
    state.statisticsAdvertiser.isLoading = false;
    state.statisticsAdvertiser.error = true;
  },
};

const actions = {
  [GET_WEBMASTER_STATISTICS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(GET_WEBMASTER_STATISTICS_START);
      statisticsApi
        .getWebmasterStatistics(payload)
        .then((response) => {
          commit(GET_WEBMASTER_STATISTICS_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_WEBMASTER_STATISTICS_FAILURE);
          reject();
        });
    });
  },
  [GET_ADVERTISER_STATISTICS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(GET_ADVERTISER_STATISTICS_START);
      statisticsApi
        .getAdvertiserStatistics(payload)
        .then((response) => {
          commit(GET_ADVERTISER_STATISTICS_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_ADVERTISER_STATISTICS_FAILURE);
          reject();
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
