import adminApi from '@/api/admin';
import { setItem } from '@/helpers/persistance-storage';

import {
  GET_SITES_LIST_START,
  GET_SITES_LIST_SUCCESS,
  GET_SITES_LIST_FAILURE,
  EDIT_SITE_START,
  EDIT_SITE_SUCCESS,
  EDIT_SITE_FAILURE,
  GET_CREATIVES_LIST_START,
  GET_CREATIVES_LIST_SUCCESS,
  GET_CREATIVES_LIST_FAILURE,
  EDIT_CREATIVE_START,
  EDIT_CREATIVE_SUCCESS,
  EDIT_CREATIVE_FAILURE,
  GET_STATISTICS_START,
  GET_STATISTICS_SUCCESS,
  GET_STATISTICS_FAILURE,
  GET_FULL_WIDGETS_LIST_START,
  GET_FULL_WIDGETS_LIST_SUCCESS,
  GET_FULL_WIDGETS_LIST_FAILURE,
  GET_FULL_CREATIVES_LIST_START,
  GET_FULL_CREATIVES_LIST_SUCCESS,
  GET_FULL_CREATIVES_LIST_FAILURE,
  GET_FULL_SITES_LIST_START,
  GET_FULL_SITES_LIST_SUCCESS,
  GET_FULL_SITES_LIST_FAILURE,
  GET_FULL_ADVERTISERS_LIST_START,
  GET_FULL_ADVERTISERS_LIST_SUCCESS,
  GET_FULL_ADVERTISERS_LIST_FAILURE,
  GET_ADVERTISERS_LIST_START,
  GET_ADVERTISERS_LIST_SUCCESS,
  GET_ADVERTISERS_LIST_FAILURE,
  GET_WEBMASTERS_LIST_START,
  GET_WEBMASTERS_LIST_SUCCESS,
  GET_WEBMASTERS_LIST_FAILURE,
  GET_USER_TOKEN_START,
  GET_USER_TOKEN_SUCCESS,
  GET_USER_TOKEN_FAILURE,
  EDIT_ADVERTISER_PROFILE_START,
  EDIT_ADVERTISER_PROFILE_SUCCESS,
  EDIT_ADVERTISER_PROFILE_FAILURE,
  GET_USER_PROFILE_START,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
} from '@/store/mutation-types/admin';

import {
  GET_SITES_LIST,
  EDIT_SITE,
  GET_CREATIVES_LIST,
  EDIT_CREATIVE,
  GET_STATISTICS,
  GET_FULL_WIDGETS_LIST,
  GET_FULL_CREATIVES_LIST,
  GET_FULL_SITES_LIST,
  GET_FULL_ADVERTISERS_LIST,
  GET_ADVERTISERS_LIST,
  GET_WEBMASTERS_LIST,
  GET_USER_TOKEN,
  EDIT_ADVERTISER_PROFILE,
  GET_USER_PROFILE,
} from '@/store/action-types/admin';

export default {
  [GET_SITES_LIST]({ commit }, payload) {
    return new Promise((resolve) => {
      commit(GET_SITES_LIST_START);
      adminApi
        .getSitesList(payload)
        .then((response) => {
          commit(GET_SITES_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_SITES_LIST_FAILURE);
        });
    });
  },
  [EDIT_SITE]({ commit }, { id, site }) {
    return new Promise((resolve, reject) => {
      commit(EDIT_SITE_START);
      adminApi
        .editSite(id, site)
        .then(() => {
          commit(EDIT_SITE_SUCCESS);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(EDIT_SITE_FAILURE);
          reject();
        });
    });
  },
  [GET_CREATIVES_LIST]({ commit }, payload) {
    return new Promise((resolve) => {
      commit(GET_CREATIVES_LIST_START);
      adminApi
        .getCreativesList(payload)
        .then((response) => {
          commit(GET_CREATIVES_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_CREATIVES_LIST_FAILURE);
        });
    });
  },
  [EDIT_CREATIVE]({ commit }, { id, creative }) {
    return new Promise((resolve, reject) => {
      commit(EDIT_CREATIVE_START);
      adminApi
        .editCreative(id, creative)
        .then(() => {
          commit(EDIT_CREATIVE_SUCCESS);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(EDIT_CREATIVE_FAILURE);
          reject();
        });
    });
  },
  [GET_FULL_WIDGETS_LIST]({ commit }) {
    return new Promise((resolve) => {
      commit(GET_FULL_WIDGETS_LIST_START);
      adminApi
        .getFullWidgetsList()
        .then((response) => {
          commit(GET_FULL_WIDGETS_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_FULL_WIDGETS_LIST_FAILURE);
        });
    });
  },
  [GET_FULL_CREATIVES_LIST]({ commit }) {
    return new Promise((resolve) => {
      commit(GET_FULL_CREATIVES_LIST_START);
      adminApi
        .getFullCreativesList()
        .then((response) => {
          commit(GET_FULL_CREATIVES_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_FULL_CREATIVES_LIST_FAILURE);
        });
    });
  },
  [GET_FULL_SITES_LIST]({ commit }) {
    return new Promise((resolve) => {
      commit(GET_FULL_SITES_LIST_START);
      adminApi
        .getFullSitesList()
        .then((response) => {
          commit(GET_FULL_SITES_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_FULL_SITES_LIST_FAILURE);
        });
    });
  },
  [GET_FULL_ADVERTISERS_LIST]({ commit }) {
    return new Promise((resolve) => {
      commit(GET_FULL_ADVERTISERS_LIST_START);
      adminApi
        .getFullAdvertisersList()
        .then((response) => {
          commit(GET_FULL_ADVERTISERS_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_FULL_ADVERTISERS_LIST_FAILURE);
        });
    });
  },
  [GET_STATISTICS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(GET_STATISTICS_START);
      adminApi
        .getStatistics(payload)
        .then((response) => {
          commit(GET_STATISTICS_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_STATISTICS_FAILURE);
          reject();
        });
    });
  },
  [GET_ADVERTISERS_LIST]({ commit }, payload) {
    return new Promise((resolve) => {
      commit(GET_ADVERTISERS_LIST_START);
      adminApi
        .getAdvertisersList(payload)
        .then((response) => {
          commit(GET_ADVERTISERS_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_ADVERTISERS_LIST_FAILURE);
        });
    });
  },
  [GET_WEBMASTERS_LIST]({ commit }, payload) {
    return new Promise((resolve) => {
      commit(GET_WEBMASTERS_LIST_START);
      adminApi
        .getWebmastersList(payload)
        .then((response) => {
          commit(GET_WEBMASTERS_LIST_SUCCESS, response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_WEBMASTERS_LIST_FAILURE);
        });
    });
  },
  [GET_USER_TOKEN]({ commit }, { userId }) {
    return new Promise((resolve, reject) => {
      commit(GET_USER_TOKEN_START);
      adminApi
        .getUserToken(userId)
        .then((response) => {
          setItem('accessToken', {
            expires: response.data.expires,
            token: response.data.token,
          });
          commit(GET_USER_TOKEN_SUCCESS);
          resolve(response.data.token);
        })
        .catch((error) => {
          console.error(error);
          commit(GET_USER_TOKEN_FAILURE);
          reject();
        });
    });
  },
  [EDIT_ADVERTISER_PROFILE]({ commit }, { id, formData }) {
    return new Promise((resolve, reject) => {
      commit(EDIT_ADVERTISER_PROFILE_START);
      adminApi
        .editAdvertiserProfile(id, formData)
        .then(() => {
          commit(EDIT_ADVERTISER_PROFILE_SUCCESS);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(EDIT_ADVERTISER_PROFILE_FAILURE);
          reject();
        });
    });
  },
  [GET_USER_PROFILE]({ commit }, { id }) {
    return new Promise((resolve) => {
      commit(GET_USER_PROFILE_START);
      adminApi
        .getUserProfile(id)
        .then((userProfile) => {
          commit(GET_USER_PROFILE_SUCCESS, userProfile.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_USER_PROFILE_FAILURE);
        });
    });
  },
};
