import Vue from 'vue';

export const roles = [
  {
    value: 'ADVERTISER',
    label: 'Advertiser',
  },
  {
    value: 'WEBMASTER',
    label: 'Webmaster',
  },
];

export const limits = [
  { value: '50', label: '50' },
  { value: '100', label: '100' },
  { value: '200', label: '200' },
  { value: '500', label: '500' },
  // 1000 as 'VERY BIG NUMBER', by the advice of @x25ru
  { value: '1000', label: 'all', trans: 'ui.all' },
];

export const mostbetLink = 'https://mostbet.com';

export const filtersDefaultLimit = 200;

export const translateConsts = (vueObject) => {
  limits.forEach((item) => {
    if (item.trans) {
      item.label = vueObject.$t(item.trans);
    }
  });
};
