import rolesApi from '@/api/roles';
import { setItem } from '@/helpers/persistance-storage';

import { GET_ROLES } from '@/store/action-types/roles';

import { GET_ROLES_START, GET_ROLES_SUCCESS, GET_ROLES_FAILURE, CLEAR_ROLES } from '@/store/mutation-types/roles';

const state = {
  roles: [],
  isLoading: false,
  errors: null,
};

const mutations = {
  [GET_ROLES_START](state) {
    state.isLoading = true;
    state.roles = [];
    state.error = null;
  },
  [GET_ROLES_SUCCESS](state, payload) {
    state.isLoading = false;
    state.roles = payload;
  },
  [GET_ROLES_FAILURE](state) {
    state.isLoading = false;
    state.error = true;
  },
  [CLEAR_ROLES](state) {
    state.roles = [];
  },
};

const actions = {
  [GET_ROLES]({ commit }) {
    return new Promise((resolve, reject) => {
      commit(GET_ROLES_START);
      rolesApi
        .getRoles()
        .then((response) => {
          commit(GET_ROLES_SUCCESS, response.data);
          setItem('roles', response.data);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          commit(GET_ROLES_FAILURE);
          reject();
        });
    });
  },
};

const getters = {
  isAdministrator(state) {
    return state.roles.includes('ROLE_ADMINISTRATOR');
  },
  isWebmaster(state) {
    return state.roles.includes('ROLE_WEBMASTER');
  },
  isAdvertiser(state) {
    return state.roles.includes('ROLE_ADVERTISER');
  },
  isStatistic(state) {
    return state.roles.includes('ROLE_STATISTIC');
  },
  roles(state) {
    return state.roles;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
