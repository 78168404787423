export const GET_BLOCKS_LIST_START = 'GET_BLOCKS_LIST_START';
export const GET_BLOCKS_LIST_SUCCESS = 'GET_BLOCKS_LIST_SUCCESS';
export const GET_BLOCKS_LIST_FAILURE = 'GET_BLOCKS_LIST_FAILURE';
export const GET_SITE_BLOCKS_LIST_START = 'GET_SITE_BLOCKS_LIST_START';
export const GET_SITE_BLOCKS_LIST_SUCCESS = 'GET_SITE_BLOCKS_LIST_SUCCESS';
export const GET_SITE_BLOCKS_LIST_FAILURE = 'GET_SITE_BLOCKS_LIST_FAILURE';
export const CREATE_BLOCK_START = 'CREATE_BLOCK_START';
export const CREATE_BLOCK_SUCCESS = 'CREATE_BLOCK_SUCCESS';
export const CREATE_BLOCK_FAILURE = 'CREATE_BLOCK_FAILURE';
export const GET_BLOCK_START = 'GET_BLOCK_START';
export const GET_BLOCK_SUCCESS = 'GET_BLOCK_SUCCESS';
export const GET_BLOCK_FAILURE = 'GET_BLOCK_FAILURE';
export const EDIT_BLOCK_START = 'EDIT_BLOCK_START';
export const EDIT_BLOCK_SUCCESS = 'EDIT_BLOCK_SUCCESS';
export const EDIT_BLOCK_FAILURE = 'EDIT_BLOCK_FAILURE';
export const GET_BLOCK_CODE_START = 'GET_BLOCK_CODE_START';
export const GET_BLOCK_CODE_SUCCESS = 'GET_BLOCK_CODE_SUCCESS';
export const GET_BLOCK_CODE_FAILURE = 'GET_BLOCK_CODE_FAILURE';
