export const GET_CAMPAIGN_LIST_START = 'GET_CAMPAIGN_LIST_START';
export const GET_CAMPAIGN_LIST_SUCCESS = 'GET_CAMPAIGN_LIST_SUCCESS';
export const GET_CAMPAIGN_LIST_FAILURE = 'GET_CAMPAIGN_LIST_FAILURE';
export const CREATE_CAMPAIGN_START = 'CREATE_CAMPAIGN_START';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAILURE = 'CREATE_CAMPAIGN_FAILURE';
export const CLONE_CAMPAIGN_START = 'CLONE_CAMPAIGN_START';
export const CLONE_CAMPAIGN_SUCCESS = 'CLONE_CAMPAIGN_SUCCESS';
export const CLONE_CAMPAIGN_FAILURE = 'CLONE_CAMPAIGN_FAILURE';
export const CREATE_CAMPAIGN_CREATIVE_START = 'CREATE_CAMPAIGN_CREATIVE_START';
export const CREATE_CAMPAIGN_CREATIVE_SUCCESS = 'CREATE_CAMPAIGN_CREATIVE_SUCCESS';
export const CREATE_CAMPAIGN_CREATIVE_FAILURE = 'CREATE_CAMPAIGN_CREATIVE_FAILURE';
export const GET_CAMPAIGN_START = 'GET_CAMPAIGN_START';
export const GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS';
export const GET_CAMPAIGN_FAILURE = 'GET_CAMPAIGN_FAILURE';
export const EDIT_CAMPAIGN_START = 'EDIT_CAMPAIGN_START';
export const EDIT_CAMPAIGN_SUCCESS = 'EDIT_CAMPAIGN_SUCCESS';
export const EDIT_CAMPAIGN_FAILURE = 'EDIT_CAMPAIGN_FAILURE';
export const GET_DOMAIN_LIST_START = 'GET_DOMAIN_LIST_START';
export const GET_DOMAIN_LIST_SUCCESS = 'GET_DOMAIN_LIST_SUCCESS';
export const GET_DOMAIN_LIST_FAILURE = 'GET_DOMAIN_LIST_FAILURE';
export const GET_WEBMASTERS_LIST_START = 'GET_WEBMASTERS_LIST_START';
export const GET_WEBMASTERS_LIST_SUCCESS = 'GET_WEBMASTERS_LIST_SUCCESS';
export const GET_WEBMASTERS_LIST_FAILURE = 'GET_WEBMASTERS_LIST_FAILURE';
